import React, { useState, useContext,useEffect } from "react";
import "./SettingsModal.scss";
import TextField from "../TextField/TextField";
import Constant from "../../util/constant";
import HookForm from "../HookForm/HookForm";
import CustomButton from "../CustomButton/button";
import CustomDropzone from "../CustomDropzone/CustomDropzone";
import { SketchPicker } from "react-color";
import MainContext from "../../MainContext";
import Spinner from "react-bootstrap/Spinner";
import WebService from "../../util/webService";

const homepageForm = {
  oClubId: {
    name: "oClubId",
    validate: {
      required: {
        value: true,
        message: "Please select club.",
      },
    },
  },
  name: {
    name: "name",
    validate: {
      required: {
        value: true,
        message: "Name cannot be empty",
      },
      pattern: {
        value: Constant.REGEX.OnlySpaces,
        message: "Name cannot be empty",
      },
    },
  },
  sProfileImage: {
    name: "sProfileImage",
  },
  url: {
    name: "url",
    validate: {
      required: {
        value: true,
        message: "URL can't be empty",
      },
      validate: {
        hasValidUrl: (value) =>
          (value && value.match(Constant.REGEX.Url)) || "Invalid URL",
      },
    },
  },
};

const SettingsModal = (props) => {
  let { closeClick, onFormSubmit, defaultForm } = props;
  const { isButtonLoading } = useContext(MainContext);
  const [clubIds] = useState(defaultForm?.selectedClub ? defaultForm?.selectedClub : null);
  const [clubItems, setClubItems] = useState([]);
  const getClubs = async () => {
    let param = {};
    await WebService.postWebServices(
      Constant.API.CLUB_LIST,
      param,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data;
          if (response.data.status === true) {
            const clubs = mainData.map((club) => {
              return { value: club._id, label: club.sClubName, sClubIdentifier: club.sClubIdentifier};
            });
            setClubItems(clubs);
          } else {
            setClubItems([]);
          }
        }
      }
    );
  };
  useEffect(() => {
    getClubs();
  }, []);
  return (
    <div className="HomepagemodalMain">
      <HookForm
        defaultForm={defaultForm}
        // onSubmit={(formMethod) => this.onFormSubmit(formMethod)}>
        onSubmit={onFormSubmit}
      >
        {(formMethod, Controller) => {
                    console.log(formMethod.getValues(), "errors====");

          return (
            <>
              <div className="uploadSec">
                <Controller
                  defaultValue={""}
                  name={homepageForm.sProfileImage.name}
                  control={formMethod?.control}
                  rules={homepageForm.sProfileImage.validate}
                  errors={formMethod?.errors}
                  as={
                    <CustomDropzone
                      {...props}
                      type="profileImgDropzone"
                      uploadTitle="Upload button icon"
                    />
                  }
                />
              </div>

              <div className="formSec">
                <TextField
                  labelName="SELECT CLUB"
                  redStar={true}
                  isDropdown={true}
                  formMethod={formMethod}
                  name={homepageForm.oClubId.name}
                  errors={formMethod?.errors}
                  rules={homepageForm.oClubId.validate}
                  options={clubItems}
                  selectedOptions={clubIds}
                />
                <TextField
                  labelName="NAME"
                  redStar={true}
                  formMethod={formMethod}
                  register={formMethod?.register(homepageForm.name.validate)}
                  name={homepageForm.name.name}
                  errors={formMethod?.errors}
                  autoFocus={true}
                  type="text"
                  placeholder="Enter Name"
                />
                <TextField
                  labelName="URL"
                  redStar={true}
                  formMethod={formMethod}
                  register={formMethod?.register(homepageForm.url.validate)}
                  name={homepageForm.url.name}
                  errors={formMethod?.errors}
                  type="text"
                  placeholder="Enter URL"
                />
                {/* <div className="colorSec">
                  <label>BUTTON COLOR<i>*</i></label>
                  <div className="swatch" onClick={() => setColorPicker(!isColorpickerOpen)}>
                    <div className="color" style={{ background: selectColor }} />
                  </div>
                  <div className="popover">
                    {isColorpickerOpen && <div className="cover" onClick={() => setColorPicker(!isColorpickerOpen)} />}
                    <SketchPicker
                      className={isColorpickerOpen ? "visible" : "notVisible"}
                      color={selectColor}
                      onChange={(color) => {
                        colorChange(color)
                      }} />
                  </div>
                </div> */}
                <div className="btnGroup">
                  <CustomButton
                    type="submit"
                    disabled={!formMethod?.formState.isValid || isButtonLoading}
                    title={
                      !isButtonLoading ? (props.isEdit ? "Update" : "Add") : ""
                    }
                  >
                    {isButtonLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </CustomButton>
                  <CustomButton
                    className="cancelBtn"
                    onClick={closeClick}
                    title="Cancel"
                  />
                </div>
              </div>
            </>
          );
        }}
      </HookForm>
    </div>
  );
};

SettingsModal.defaultProps = {
  // btnTitle: ""
};
export default SettingsModal;
