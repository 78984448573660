import React, { useState, useContext, useEffect } from "react";
import "./CustomMenuBar.scss";
import { Scrollbars } from "react-custom-scrollbars";
// import CustomButton from '../CustomButton/button';
import userIcon from "../../assets/images/userIcon.svg";
import userActive from "../../assets/images/userActive.svg";
import shieldIcon from "../../assets/images/shieldIcon.svg";
import shieldActive from "../../assets/images/shieldActive.svg";
// import contactIcon from "../../assets/images/contactIcon.svg";
// import contactActive from "../../assets/images/contactActive.svg";
import homeIcon from "../../assets/images/homeIcon.svg";
import homeActive from "../../assets/images/homeActive.svg";
import { useLocation } from "react-router-dom";
import MainContext from "../../MainContext";
import CustomModal from "../../components/Modal/Modal";
import Constant from "../../util/constant";
import WebService from "../../util/webService";
import ToastComponent from "../../components/ToastComponent/ToastComponent";
import { getFormDataObj, uploadFile, showToast, closeToast, uploadClubLogo } from "../../util/helper";

// import logoYellow from "../../assets/images/logo_yellow.png";
const logoYellow = "https://res.cloudinary.com/dtzkljk1x/image/upload/w_130,c_scale/v1611649515/Community-Club/Logo_Black_kpqcvk.png";

const PAGENO = 1;
const LIMIT = 10;

const CustomMenuBar = (props) => {
  const { isDrawerOpen, updateDrawer } = useContext(MainContext);
  const [searchText, setSearchText] = useState("");
  const [pageNo,setPageNo] = useState(PAGENO);
  const [sortBy, setSortBy] = useState("");
  const { updateButtonLoading } = useContext(MainContext);
  const { contextToast, updateToast } = useContext(MainContext);
  const [isToggled, setIsToggled] = useState(isDrawerOpen);
  const [subMenuUser, setsubMenuUser] = useState(false);
  const [subMenuClub, setsubMenuClub] = useState(false);
  const [subMenuContact, setsubMenuContact] = useState(false);
  const [subMenuHomepage, setsubMenuHomepage] = useState(false);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [clubModalVisible, setClubModalVisible] = useState(false);
  const [contactUserModalVisible, setContactUserModalVisible] = useState(false);
  const [homepageModalVisible, setHomepageModalVisible] = useState(false);
  const [recentClubs, setRecentClubs] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [iClubId, setClubId] = useState("");
  const [pageLinkList, setPageLinkList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [noData, setNoData] = useState('');
  const [toast, setToast] = useState({
    show: false,
    type: "Sucess",
    msg: "The club has been edited successfully.",
    errorToast: false,
  });
  let location = useLocation();
  let currentPage = location.pathname;
  let CLUBLISTING = "/clubs";
  let USERSLISTING = "/users";
  let VIEWCLUB = "/club";
  let FIXTURESTABLESLISTING = "/club/fixtures";
  let CLUBSPONSERSLISTING = "/club/sponsors";
  let HOMEPAGELISTING = "/homepagelisting";
  let CONTACTUSERSLISTING = "/club/contacts";
  
  const onClubSubmitClick = async (values) => {
    updateButtonLoading(true);
    var formData = new FormData();
    var apiUrl = Constant.API.CLUB_ADD;
    if (values.sClubLogo !== "") {
      let logoUrl = await uploadClubLogo(values.sClubLogo, values.sClubName.replace(/\s+/g, '-').toLowerCase())
      formData.append("sClubLogo", logoUrl);
    }
    formData.append("sClubName", values.sClubName);
    formData.append("sClubIdentifier", values.sClubName.replace(/\s+/g, '-').toLowerCase());
    await WebService.postWebServices(apiUrl, formData, (response) => {
      if (response.status || response.status >= 200 || response.status <= 210) {
        if (response.data.status === true) {
          updateToast(false, "Success", response.data.msg, false);
          setClubModalVisible(false);
          //If current page is same page then reload it.
          if (currentPage === CLUBLISTING) {
            props.history.push("users");
            props.history.push("clubs");
          }
        }
      }
    });
    updateButtonLoading(false);
  };
  const onUserSubmitClick = async (values) => {
    updateButtonLoading(true);
    var formData = new FormData();
    var apiUrl = Constant.API.USER_ADD;
    formData.append("_UserRoleId", "UR002");
    if (values.sProfilePic !== "") {
      const uploadImage = await uploadFile(values.sProfilePic, values.oClubId.sClubIdentifier, 'user_profile/');
      if (uploadImage) {
        formData.append("sProfilePic", uploadImage);
      }
    }
    console.log("values===>", values);
    formData.append(
      "sClubidentifier",
      values.oClubId.sClubIdentifier
    );
    formData.append("sFullName", values.sFullName);
    formData.append("sEmailId", values.sEmailId);
    formData.append("nPhoneNumber", values.nPhoneNumber);

    await WebService.postWebServices(apiUrl, formData, (response) => {
      if (response.status || response.status >= 200 || response.status <= 210) {
        if (response.data.status === true) {
          updateToast(false, "Success", response.data.msg, false);
          setUserModalVisible(false);
          //If current page is same page then reload it.
          if (currentPage === USERSLISTING) {
            props.history.push("clubs");
            props.history.push("users");
          }
        }
      }
    });
    updateButtonLoading(false);
  };
  const recentlyAddedClubs = async () => {
    await WebService.getWebServices(
      Constant.API.RECENT_CLUB_LIST,
      {},
      (response) => {
        if (
          response.status ||
          response.status >= 200 ||
          response.status <= 210
        ) {
          // if (response.data.status === true) {
          setRecentClubs(response.data.data);
          // }
        }
      }
    );
  };
  const onDeleteClub = async (id) => {
    setDeleteModalVisible(false);
    setClubId("");
    let param = {
      iClubId: id,
    };
    await WebService.postWebServices(
      Constant.API.CLUB_DELETE,
      param,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            updateToast(false, "Success", response.data.msg, false);
            recentlyAddedClubs();
            props.refreshClubs();
          } else {
            updateToast(true, "Failed", response.data.msg, true);
          }
        }
      }
    );
    // setTimeout(() => {
    //   //console.log(contextToast,'TOAST DATA');
    //   // updateToast(false,contextToast.type,contextToast.msg,contextToast.errorToast);
    // }, 5000);
  };

  const onFormSubmit = (values, e) => {
      addLinkWebService(values, e);
  };

  const addLinkWebService = async (values) => {
    updateButtonLoading(true);
    let toastMsg;

    let sImage = await uploadFile(values.sProfileImage, values.oClubId.sClubIdentifier, 'homepage_links/');

    let param = {
      sLink: values.url,
      sTitle: values.name,
      sImage: sImage,
      sClubIdentifier: values.oClubId.sClubIdentifier,
    };

    let formData = getFormDataObj(param);
    await WebService.postWebServices(
      Constant.API.ADD_HOMEPAGE_LINK + `?sClubIdentifier=${values.oClubId.sClubIdentifier}`,
      formData,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          // let mainData = response.data.data;
          if (response.data.status) {
            toastMsg = showToast("Success", response.data.msg);
            // this.setState({
            //   HomepageModalVisible: false,
            //   toast: toastMsg,
            //   btnColorL: "#000000",
            // });
            setToast(toastMsg);
            setHomepageModalVisible(false);
            getHomepageListingWebService();
            updateButtonLoading(false);
          } else {
            toastMsg = showToast("Failed", response.data.msg);
            // this.setState({ toast: toastMsg });
            setToast(toastMsg);
          }
        }
      }
    );
    // setTimeout(() => {
    //   setToast(
    //     closeToast(
    //       this.state.toast.type,
    //       this.state.toast.msg,
    //       this.state.toast.errorToast
    //     ),
    //   );
    // }, 5000);
    updateButtonLoading(false);
  };
  
  const getHomepageListingWebService = async () => {
    let param = {
      // sClubIdentifier: "grenta-fc",
      searchText: searchText,
      page: pageNo,
      limit: LIMIT,
      sortBy: sortBy,
    };
    // const { updateButtonLoading } = this.context;
    updateButtonLoading(true);
    // useEffect(() => {

        // API call with await
        const response = await WebService.postWebServices(Constant.API.GET_HOMEPAGE_LINK, param);

        updateButtonLoading(false);

        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            setPageLinkList(response.data.data);
            setTotalRecords(response.data.totalRecords);
          } else {
            setPageLinkList([]);
          }
        }
      // };


    await WebService.getWebServices(
      Constant.API.HOMEPAGE_EXPORT_LIST,
      param,
      (response) => {
        updateButtonLoading(false);
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data;
          if (response.data.status === true) {
            var exportData = [];
            mainData.map((d) => {
              exportData.push({
                Name: d.sTitle,
                Link: d.sLink,
                Club: d.sClubName,
              });
            });
            this.setState(
              {
                exportList: exportData,
              },
              () => { }
            );
          } else {
            this.setState({
              exportList: [],
              NoData: mainData.msg,
            });
          }
        }
      }
    );
  };

  useEffect(() => {
    recentlyAddedClubs();
  }, [props.clubListUpdated]);

  const timeSince = (date) => {
    //https://stackoverflow.com/questions/3177836/how-to-format-time-since-xxx-e-g-4-minutes-ago-similar-to-stack-exchange-site
    if (typeof date !== "object") {
      date = new Date(date);
    }

    var seconds = Math.floor((new Date() - date) / 1000);
    var intervalType;

    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      intervalType = "year";
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        intervalType = "month";
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = "day";
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = "hour";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = "minute";
            } else {
              interval = seconds;
              intervalType = "a few second";
            }
          }
        }
      }
    }
    if (interval > 1 || interval === 0) {
      intervalType += "s ago";
    } else {
      intervalType += " ago";
    }

    return interval + " " + intervalType;
  };
  return (
    <div className="mainSide">
      <ToastComponent
        onClose={() => {
          updateToast(false, contextToast.type, contextToast.msg, contextToast.errorToast);
        }}
        show={contextToast.show}
        toastMessage={contextToast.msg}
        toastMessageTye={contextToast.type}
        errorToast={contextToast.errorToast}
      />
      <a className="lineIcon" onClick={() => setIsToggled(!isToggled)}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </a>
      <div className={!isToggled ? "CustomMenuBar" : "CustomMenuBar barclose"}>
        <div className="sideBar">
          <div className="logoSec">
            <a
              onClick={() => {
                props.history.push("/dashboard");
              }}
            >
              <div className="logo">
                <img src={logoYellow} className="img" alt="" />
              </div>
              {/* <span className="headerTxt">COMMUNITY CLUB INITIATIVES</span> */}
            </a>

            <a className="closeIcon" onClick={() => setIsToggled(!isToggled)}>
              <i className="icon-close"></i>
            </a>
          </div>
          <div className="menuBar">
            <span className="greyTxt">{!isToggled ? "Home" : ""}</span>
            <ul>
              <div className="LinkSec">
                <li
                  className={
                    currentPage === CLUBLISTING ||
                      currentPage === VIEWCLUB ||
                      currentPage === FIXTURESTABLESLISTING ||
                      currentPage === CLUBSPONSERSLISTING
                      ? "simple active"
                      : "simple"
                  }
                >
                  <a
                    className="textLink"
                    onClick={() => {
                      props.history.push("/clubs");
                    }}
                  >
                    <img
                      src={
                        currentPage === CLUBLISTING ||
                          currentPage === VIEWCLUB ||
                          currentPage === FIXTURESTABLESLISTING ||
                          currentPage === CLUBSPONSERSLISTING
                          ? shieldActive
                          : shieldIcon
                      }
                      className="menuIcon"
                      alt=""
                    />
                    <span>List of Clubs</span>
                  </a>
                  <a
                    className="arrow"
                    onClick={(e) => {
                      e.stopPropagation();
                      setsubMenuClub(!subMenuClub);
                    }}
                  >
                    {" "}
                    <i
                      className={
                        subMenuClub
                          ? "icon-right-arrow right down"
                          : "icon-right-arrow right"
                      }
                    ></i>
                  </a>
                </li>
              </div>
              <div className={subMenuClub ? "submenu active" : "submenu"}>
                <a
                  className="submenuLine"
                  onClick={() => setClubModalVisible(!clubModalVisible)}
                >
                  <span className="submenuTxt">Add Club</span>
                </a>
              </div>
              <div className="LinkSec">
                <li
                  className={
                    currentPage === USERSLISTING ? "simple active" : "simple"
                  }
                >
                  <a
                    className="textLink"
                    onClick={() => {
                      props.history.push("/users");
                    }}
                  >
                    <img
                      src={currentPage === USERSLISTING ? userActive : userIcon}
                      className="menuIcon"
                      alt=""
                    />
                    <span>List of Users</span>
                  </a>
                  <a
                    className="arrow"
                    onClick={
                      ((e) => e.stopPropagation(),
                        () => setsubMenuUser(!subMenuUser))
                    }
                  >
                    {" "}
                    <i
                      className={
                        subMenuUser
                          ? "icon-right-arrow right down"
                          : "icon-right-arrow right"
                      }
                    ></i>
                  </a>
                </li>
              </div>
              <div className={subMenuUser ? "submenu active" : "submenu"}>
                <a
                  className="submenuLine"
                  onClick={() => setUserModalVisible(!userModalVisible)}
                >
                  <span className="submenuTxt">Add User</span>
                </a>
              </div>
              <div className="LinkSec">
                <li
                  className={
                    currentPage === HOMEPAGELISTING ? "simple active" : "simple"
                  }
                >
                  <a
                    className="textLink"
                    onClick={() => {
                      props.history.push(HOMEPAGELISTING);
                    }}
                  >
                    <img
                      src={
                        currentPage === HOMEPAGELISTING ? homeActive : homeIcon
                      }
                      className="menuIcon"
                      alt=""
                    />
                    <span>Settings Links</span>
                  </a>
                  <a
                    className="arrow"
                    onClick={(e) => {
                      e.stopPropagation();
                      setsubMenuHomepage(!subMenuHomepage);
                    }}
                  >
                    {" "}
                    <i
                      className={
                        subMenuHomepage
                          ? "icon-right-arrow right down"
                          : "icon-right-arrow right"
                      }
                    ></i>
                  </a>
                </li>
              </div>
              <div className={subMenuHomepage ? "submenu active" : "submenu"}>
                <a
                  className="submenuLine"
                  onClick={() => setHomepageModalVisible(!homepageModalVisible)}
                >
                  <span className="submenuTxt">Add Settings Link</span>
                </a>
              </div>
            </ul>
          </div>
          <div className="clubListing">
            <span className="greyTxt">
              {!isToggled
                ? recentClubs.length > 0
                  ? "Recently added clubs"
                  : "No recently added clubs"
                : ""}
            </span>
            <Scrollbars className="listing">
              <ul>
                {recentClubs.length > 0 &&
                  recentClubs.map((club, i) => {
                    return (
                      <li className="clubRow" key={i}>
                        <div className="clubImgSec">
                          <img
                            src={club.sClubLogo.replace("upload/", "upload/w_29,c_scale/")}
                            className="clubImg"
                            alt="Logo"
                          />
                        </div>
                        <div className="rightClubSec">
                          <div className="txtSec">
                            <span className="boldTxt">{club.sClubName}</span>
                            <span className="greyyTxt">
                              {timeSince(new Date(club.dCreatedAt))}
                            </span>
                          </div>
                          <a
                            className="deleteClick"
                            onClick={() => {
                              setClubId(club._id);
                              setDeleteModalVisible(true);
                            }}
                          >
                            <i className="icon-DeleteNew"></i>
                          </a>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </Scrollbars>
          </div>

          <div className="toggleSec">
            <span className="toggle">
              <input
                type="checkbox"
                checked={!isToggled}
                id="togglemenu"
                className="togglebox"
                onChange={() => {
                  if (isDrawerOpen) {
                    updateDrawer(false);
                    setIsToggled(false);
                  } else {
                    updateDrawer(true);
                    setIsToggled(true);
                  }
                }}
              />
              <label htmlFor="togglemenu" className="toggleLabel"></label>
            </span>
            <span className="togglename">Toggle Sidebar</span>
          </div>
        </div>
      </div>
      <CustomModal
        showModal={deleteModalVisible}
        _id={iClubId}
        onHide={() => {
          setDeleteModalVisible(false);
          setClubId("");
        }}
        isStatusModal={false}
        titleName={"Delete"}
        modalType="deleteModal"
        deletItemName="this club?"
        onClick={(id) => {
          onDeleteClub(id);
        }}
      />
      <CustomModal
        oUserId={""}
        showModal={userModalVisible}
        titleName="Add New User"
        onHide={() => setUserModalVisible(!userModalVisible)}
        modalType="userModal"
        sFullName={""}
        sEmailId={""}
        nPhoneNumber={""}
        defaultForm={{}}
        sProfilePic={""}
        selectedOptions={[]}
        onSubmit={(details) => {
          onUserSubmitClick(details);
        }}
      />
      <CustomModal
        showModal={clubModalVisible}
        titleName="Add Details"
        clubNamePlaceholder="Club Name"
        onHide={() => setClubModalVisible(!clubModalVisible)}
        modalType="clubModal"
        _id={""}
        sClubName={""}
        sClubLogo={""}
        onSubmit={(details) => {
          onClubSubmitClick(details);
        }}
      />
      <CustomModal
        showModal={contactUserModalVisible}
        titleName="Add New Contact Us User"
        defaultForm={{}}
        onHide={() => setContactUserModalVisible(!contactUserModalVisible)}
        modalType="contactModal"
      />
      <CustomModal
        showModal={homepageModalVisible}
        titleName="Add New Settings Link"
        onHide={() => setHomepageModalVisible(!homepageModalVisible)}
        modalType="settingsModal"
        selectedColor={(color) => {
        }}
        onFormSubmit={onFormSubmit}
      />
    </div>
  );
};

CustomMenuBar.defaultProps = {};
export default CustomMenuBar;
