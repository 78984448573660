import React from "react";
import "./firsttimelogin.scss";
// import logo from "../../assets/images/logo_yellow.png";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/button";
import ToastComponent from "../../components/ToastComponent/ToastComponent";
import HookForm from "../../components/HookForm/HookForm";
import Constant from "../../util/constant";
import Message from "../../util/message";
import { Scrollbars } from "react-custom-scrollbars";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import pageLoader from "../../assets/images/pageLoader.svg";
import WebService from "../../util/webService";
const logo = "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png"

const multiErrorFields = [
  { length: "Minimum 6 characters required" },
  { hasSpecialChar: "Minimum 1 special character required" },
  {
    hasNumbers: "Minimum 1 number required",
  },
];
const randomIndexFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
const encryption = (inputString) => {
  var outputString = "";
  var asciiArr = [];
  var atozArr = [];
  var encryptedString = [];
  if (inputString.length !== 0) {
    outputString = "";
    //First Step: Convert all characters in ascii code
    for (let i = 0; i < inputString.length; i++) {
      asciiArr[i] = inputString[i].charCodeAt(0);
    }
    //Second Step: Fill AtoZ array in capital or small letters
    for (let i = 0, code = 65; i < 26; i++, code++) {
      atozArr[i] = String.fromCharCode(code);
    }
    //Third Step: Choose random single character index from A to Z
    let position = randomIndexFromInterval(0, atozArr.length - 1);

    let positionAscii = atozArr[position].charCodeAt(0);
    //Fourth Step: Addition of every inputString element to positionAscii
    for (let i = 0; i < inputString.length; i++) {
      encryptedString[i] =
        parseInt(asciiArr[i]) + parseInt(atozArr[position].charCodeAt(0));
    }
    //Fifth Step: Attach key to encrypted string
    encryptedString[asciiArr.length] = positionAscii;
    //Sixth Step: Finally your encryption is ready to send
    for (let i = 0; i < encryptedString.length; i++) {
      outputString = outputString + String.fromCharCode(encryptedString[i]);
    }
    return outputString;
  } else {
    console.log("error ", outputString);
    return false;
  }
};
const firstTimeLoginForm = {
  otp: {
    name: "otp",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.OTPEmpty,
      },
      validate: {
        length: (value) =>
          (value && value.length >= 6) || "Minimum 6 characters required",
      },
    },
  },
  password: {
    name: "password",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.NewPassword,
      },
      pattern: {
        value: Constant.REGEX.OnlySpaces,
        message: Message.ErrorMessage.NewPassword,
      },
      validate: {
        length: (value) =>
          (value && value.length >= 6) || "Minimum 6 characters required",
        hasSpecialChar: (value) =>
          (value && value.match(Constant.REGEX.SPECIAL_CHAR)) ||
          "Minimum 1 special character required",
        hasNumbers: (value) =>
          (value && value.match(Constant.REGEX.NUMBER)) ||
          "Minimum 1 number required",
      },
    },
  },
  confirmpassword: {
    name: "confirmpassword",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.ConfirmPassword,
      },
      pattern: {
        value: Constant.REGEX.OnlySpaces,
        message: Message.ErrorMessage.ConfirmPassword,
      },
    },
  },
};

class FirstTimeLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHidePassword: false,
      showPass: false,
      showConPass: false,
      toast: {
        show: false,
        type: "",
        msg: "",
        errorToast: false,
      },
      width: window.innerWidth,
      height: window.innerHeight,
      numberValue: "",
      isVerified: false,
      isLoading: false,
    };
  }
  componentDidMount() {
    let encodedEmail = this.props.location.pathname.replace(
      "/firsttimelogin/",
      ""
    );
    this.setState(
      {
        encodedEmail: encodedEmail,
      },
      () => {
        this.onVerificationCheck();
      }
    );
    // console.log("=== encodedEmail ====", encodedEmail);
  }
  onVerificationCheck = async () => {
    this.setState({ isLoading: true });
    let param = {
      resetToken: this.state.encodedEmail,
    };
    await WebService.putWebServices(
      Constant.API.USER_CHECK_FIRST_LOGIN_LINK,
      param,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            setTimeout(() => {
              this.setState({
                _userId: response.data.data._UserId,
                isVerified: true,
                isLoading: false,
              });
            }, 1500);
          } else {
            this.setState({
              isVerified: false,
              isLoading: false,
              toast: {
                show: true,
                type: "Failed",
                msg: response.data.msg,
                errorToast: true,
              },
            });
          }
        } else {
          setTimeout(() => {
            this.setState({
              isVerified: false,
              isLoading: false,
            });
          }, 1500);
        }
      }
    );
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
      if (!this.state.isVerified) {
        // this.props.history.push("/login");
      }
    }, 5000);
  };
  handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  onFormSubmit = async (values) => {
    this.setState({ isLoading: true });
    const param = {
      // sEmailId: values.email,
      sPassword: encryption(values.password),
      nOtp: encryption(values.otp),
    };

    await WebService.postWebServices(
      Constant.API.USER_FIRST_TIME_LOGIN,
      param,
      (response) => {
        console.log(response.data.club.sClubName);
        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            this.setState({
              toast: {
                show: true,
                type: "Success",
                msg: "Your password has been reset successfully.",
                errorToast: false,
              },
            });
            this.props.history.replace("/resetsuccess/"+response.data.club.sClubIdentifier, { resetSuccess: true });
            // this.props.history.push('/')
          } else {
            this.setState({
              toast: {
                show: true,
                type: "Failed",
                msg: response.data.msg,
                errorToast: true,
              },
            });
          }
        }
      }
    );
    this.setState({ isLoading: false });
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  onConfirmPassword = (e, formMethod) => {
    if (formMethod.watch("password") !== e.target.value) {
      return formMethod.setError(firstTimeLoginForm.confirmpassword.name, {
        type: "notMatch",
        message:
          e.target.value.length === 0
            ? Message.ErrorMessage.ConfirmPassword
            : Message.ErrorMessage.ConfirmPasswordInvalid,
      });
    }
  };

  onOTPChange = (formMethod, e, mask) => {
    const value = e.target.value;
    !value || value === mask
      ? formMethod.setError(firstTimeLoginForm.otp.name, {
          type: "manual",
          message: Message.ErrorMessage.OTPEmpty,
        })
      : formMethod.clearErrors(["otp"]);
  };

  render() {
    const { toast } = this.state;
    return (
      <div fluid className="firstTimeLoginPage">
        <ToastComponent
          onClose={() => {
            this.setState({
              toast: {
                show: false,
                type: this.state.toast.type,
                msg: this.state.toast.msg,
                errorToast: this.state.toast.errorToast,
              },
            });
          }}
          show={toast.show}
          toastMessage={toast.msg}
          toastMessageTye={toast.type}
          errorToast={toast.errorToast}
        />
        {this.state.isLoading && (
          <div className="wholePageLoader">
            <img src={pageLoader} alt="Loader" />
          </div>
        )}
        <div className="innerPage">
          <div className="firstTimeLoginleft">
            <Scrollbars>
              {!this.state.isLoading && !this.state.isVerified && (
                <div className="formSection err">
                  <div className="mainForm">
                    <div className="logo">
                      <div className="logoMain">
                        <img src={logo} alt="Logo" />
                      </div>
                      {/* <p className="logoTxt">COMMUNITY CLUB INITIATIVES</p> */}
                    </div>
                    <div className="error">{toast.msg}</div>
                  </div>
                </div>
              )}
              {!this.state.isLoading && this.state.isVerified && (
              <div className="formSection">
                <div className="mainForm">
                  <HookForm onSubmit={this.onFormSubmit.bind(this)}>
                    {(formMethod) => {
                      return (
                        <div>
                          <div className="logo">
                            <div className="logoMain">
                              <img src={logo} alt="Logo" />
                            </div>
                            {/* <p className="logoTxt">
                              COMMUNITY CLUB INITIATIVES
                            </p> */}
                          </div>
                          {!this.state.isLoading && this.state.isVerified && (
                            <>
                              <div className="bigTxtsec">
                                <span>First Time Login</span>
                                <p className="titleText">
                                  Choose a strong password, always...
                                </p>
                              </div>
                              <TextField
                                // maskType="999999"
                                formMethod={formMethod}
                                register={formMethod?.register(
                                  firstTimeLoginForm.otp.validate
                                )}
                                name={firstTimeLoginForm.otp.name}
                                errors={formMethod?.errors}
                                placeholder="OTP"
                                onChange={(e) =>
                                  this.onOTPChange(formMethod, e, "      ")
                                }
                                // noTextfield={true}
                                labelName="OTP"
                                redStar={true}
                                onKeyDown={this.handleKeyDown}
                              />
                              <TextField
                                redStar={true}
                                labelName="NEW PASSWORD"
                                formMethod={formMethod}
                                multiErrorFields={multiErrorFields}
                                register={formMethod?.register(
                                  firstTimeLoginForm.password.validate
                                )}
                                name={firstTimeLoginForm.password.name}
                                errors={formMethod?.errors}
                                type={this.state.showPass ? "text" : "password"}
                                showHidePassword={() => this.showHidePassword()}
                                placeholder="Password"
                                iconClass={
                                  !this.state.showPass
                                    ? "icon-eye_notvisible"
                                    : "icon-eye_visible"
                                }
                                // onKeyDown={this.onKeyDown.bind(this)}
                                onKeyDown={this.handleKeyDown}
                                onIconClick={() =>
                                  this.setState({
                                    showPass: !this.state.showPass,
                                  })
                                }
                              />
                              <TextField
                                redStar={true}
                                labelName="CONFIRM PASSWORD"
                                formMethod={formMethod}
                                register={formMethod?.register(
                                  firstTimeLoginForm.confirmpassword.validate
                                )}
                                name={firstTimeLoginForm.confirmpassword.name}
                                errors={formMethod?.errors}
                                type={
                                  this.state.showConPass ? "text" : "password"
                                }
                                showHidePassword={() => this.showHidePassword()}
                                placeholder="Password"
                                iconClass={
                                  !this.state.showConPass
                                    ? "icon-eye_notvisible"
                                    : "icon-eye_visible"
                                }
                                // onKeyDown={this.onKeyDown.bind(this)}
                                onKeyDown={this.handleKeyDown}
                                onChange={(e) =>
                                  this.onConfirmPassword(e, formMethod)
                                }
                                onIconClick={() =>
                                  this.setState({
                                    showConPass: !this.state.showConPass,
                                  })
                                }
                              />
                              <div className="checkbox-Forgot">
                                <div className="forgotLink">
                                  <a
                                    onClick={() => {
                                      this.props.history.push("/");
                                    }}
                                  >
                                    Back to Login
                                  </a>
                                </div>
                              </div>
                              <CustomButton
                                type="submit"
                                title="SUBMIT"
                                disabled={!formMethod?.formState.isValid}
                              />
                            </>
                          )}
                        </div>
                      );
                    }}
                  </HookForm>
                </div>
              </div>
              )}
            </Scrollbars>
          </div>
          <div className="firstTimeLoginright">
            <div className="imageSection">
              <ImageSlider />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FirstTimeLogin;
