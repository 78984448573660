import React from "react";
import "./userslisting.scss";
import Constant from "../../util/constant";
import SearchBox from "../../components/SearchBox/SearchBox";
import clubImg from "../../assets/images/no-img.png";
import { Scrollbars } from "react-custom-scrollbars";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CustomMenuBar from "../../components/CustomMenuBar/CustomMenuBar";
import ToastComponent from "../../components/ToastComponent/ToastComponent";
import CustomModal from "../../components/Modal/Modal";
import WebService from "../../util/webService";
import CustomPagination from "../../components/Pagination/Pagination";
import MainContext from "../../MainContext";
import Nodata from "../../components/Nodata/Nodata";
import Tooltip from "react-simple-tooltip";
import { CSVLink } from "react-csv";
import {uploadFile} from "../../util/helper";

class UsersListing extends React.Component {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    this.onSearch = this.onSearch.bind(this);
    this.state = {
      toast: {
        show: false,
        type: "Sucess",
        msg: "The club has been edited successfully.",
        errorToast: false,
      },
      users: [],
      exportList: [],
      searchText: "",
      NoData: "",
      typingTimeout: 0,
      togglepageVisible: true,
      userModalVisible: false,
      deleteModalVisible: false,
      activePage: 1,
      itemsCountPerPage: 5,
      totalItemsCount: 0,
      pageRangeDisplayed: 7,
      nPageNo: 1,
      clubItems: [],
      oUserId: "",
      defaultForm: {},
      isLoading: false,
      sortUpper: false,
      errorField: "",
      userExistsError: "",
      sortBy: { sFullName: 1 },
      start: 0,
      end: 0,
    };
  }
  onSubmitClick = async (values) => {
    const { updateButtonLoading } = this.context;
    updateButtonLoading(true);
    const formData = new FormData()
    let data = {};
    var apiUrl = Constant.API.USER_ADD;
    if (this.state.oUserId !== "") {
      formData.append("userId", this.state.oUserId);
      data.userId = this.state.oUserId;
      apiUrl = Constant.API.USER_EDIT;
    }
    formData.append('_UserRoleId', 'UR002');
    console.log("values===>", values);
    // formData.append(
    //   "iClubId",
    //   // values.oClubId.map((item) => {
    //   //   return item.value;
    //   // })
    //   values.oClubId.value
    // );
    formData.append("sClubIdentifier", values.oClubId.sClubIdentifier);
    formData.append("sFullName", values.sFullName);
    formData.append("sEmailId", values.sEmailId);
    formData.append("sCountryCode", values.sCountryCode);
    formData.append("nPhoneNumber", values.nPhoneNumber);

    const uploadImage = await uploadFile(values.sProfilePic, values.oClubId.sClubIdentifier, 'user_profile/');
    if (uploadImage) {
      formData.append("sProfilePic", uploadImage);
    }

    this.setState({ defaultForm: values });
    await WebService.postWebServices(apiUrl, formData, (response) => {
      if (response.status || response.status >= 200 || response.status <= 210) {
        console.log("response.data===>", response.data.errorField);
        if (response.data.status === true) {
          this.setState({
            userModalVisible: false,
            oUserId: "",
            toast: {
              show: true,
              type: "Success",
              msg: response.data.msg,
              errorToast: false,
            },
          });
          this.props.history.push("/clubs");
          this.props.history.push("/users");
          // this.getList();
        } else {
          this.setState({
            errorField: response.data.errorField,
            userExistsError: response.data.msg,
          });
        }
      }
    });
    updateButtonLoading(false);
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };

  onApply(toggleVisible) {
    this.setState({ togglepageVisible: toggleVisible });
  }
  componentWillMount() {
    this.getList();
  }
  onDeleteUser = async (id) => {
    const { updateLoading } = this.context;
    updateLoading(true);
    this.setState({
      deleteModalVisible: false,
    });
    let param = {
      userId: id,
    };
    await WebService.postWebServices(
      Constant.API.USER_DELETE,
      param,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            this.setState({ oUserId: "" });
            this.setState({
              toast: {
                show: true,
                type: "Success",
                msg: response.data.msg,
                errorToast: false,
              },
            });
            //If last record of the current page has been deleted, then set the page number to previous page.
            var start =
              this.state.activePage * this.state.itemsCountPerPage -
              (this.state.itemsCountPerPage - 1);
            var end = Math.min(
              start + this.state.itemsCountPerPage - 1,
              this.state.totalItemsCount
            );
            if (start === end && this.state.activePage !== 1) {
              this.setState({ nPageNo: this.state.activePage - 1 }, () => {
                this.getList();
              });
            } else {
              this.getList();
            }
          }
        } else {
          this.setState({
            toast: {
              show: true,
              type: "Failed",
              msg: response.data.msg,
              errorToast: true,
            },
          });
        }
      }
    );
    updateLoading(false);
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  onPageChange = (nPageNo) => {
    this.setState({ nPageNo: nPageNo }, () => {
      this.getList();
    });
  };

  getList = async () => {
    const { updateLoading } = this.context;
    updateLoading(true);
    let param = {
      userRoleId: "UR002",
      nPageNo: this.state.nPageNo,
      nResultsPerPage: this.state.itemsCountPerPage,
      searchText: this.state.searchText,
      sSortBy:
        typeof this.state.sortBy === "undefined"
          ? "undefined"
          : JSON.stringify({
              Key: Object.keys(this.state.sortBy)[0],
              Value: this.state.sortBy[Object.keys(this.state.sortBy)[0]],
            }),
    };
    await WebService.getWebServices(
      Constant.API.USER_LIST,
      param,
      (response) => {
        updateLoading(false);
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data.users;
          let totalPages = response.data.data.totalPages;
          if (response.data.status === true) {
            var start =
              this.state.nPageNo * this.state.itemsCountPerPage -
              (this.state.itemsCountPerPage - 1);
            mainData = mainData.map((item) => {
              item.id = start;
              start++;
              return item;
            });
            this.setState(
              {
                users: mainData,
                totalItemsCount: response.data.data.totalUsers,
                activePage: this.state.nPageNo,
                pageRangeDisplayed:
                  totalPages < this.state.pageRangeDisplayed
                    ? totalPages
                    : this.state.pageRangeDisplayed,
                totalPages: totalPages,
              },
              () => {}
            );
          } else {
            this.setState({
              users: [],
              NoData: mainData.msg,
            });
            this.setState({
              toast: {
                show: true,
                type: "Failed",
                msg: response.data.msg,
                errorToast: true,
              },
            });
          }
        }
      }
    );
    await WebService.getWebServices(
      Constant.API.USER_EXPORT_LIST,
      param,
      (response) => {
        updateLoading(false);
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data.users;
          if (response.data.status === true) {
            var exportData = [];
            mainData.map(function (d) {
              exportData.push({
                "Full Name": d.sFullName,
                "Email ID": d.sEmailId,
                "Phone Number": `${d.sCountryCode} ${d.nPhoneNumber}`,
                "Club Name": d.Club.sClubName,
              });
            });
            this.setState(
              {
                exportList: exportData,
              },
              () => {}
            );
          } else {
            this.setState({
              exportList: [],
              NoData: mainData.msg,
            });
          }
        }
      }
    );
  };
  onClear = () => {
    this.setState(
      {
        searchText: "",
      },
      () => {
        setTimeout(() => {
          this.getList();
        }, 500);
      }
    );
  };
  onSearch = (e) => {
    const self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: e.target.value,
      typingTimeout: setTimeout(function () {
        self.getList();
      }, 500),
    });
  };
  render() {
    const {
      toast,
      userModalVisible,
      deleteModalVisible,
      users,
      totalItemsCount,
      pageRangeDisplayed,
      oUserId,
      defaultForm,
      searchText,
      sortBy,
    } = this.state;

    return (
      <div className="UsersListingPage">
        <Header {...this.props} logonotVisible={true} />
        <CustomMenuBar
          onToggleClick={(toggleVisible) => this.onApply(toggleVisible)}
          {...this.props}
        />
        <div className="centerSec">
          <ToastComponent
            onClose={() => {
              this.setState({
                toast: {
                  show: false,
                  type: this.state.toast.type,
                  msg: this.state.toast.msg,
                  errorToast: this.state.toast.errorToast,
                },
              });
            }}
            show={toast.show}
            toastMessage={toast.msg}
            toastMessageTye={toast.type}
            errorToast={toast.errorToast}
          />
          <div
            className={
              this.context.isDrawerOpen ? "rightMain" : "rightMain sidePadding"
            }
          >
            <div className="searchSec">
              <div className="leftSec">
                <span className="blackdarkTxt">List of Users</span>
                <span className="divider"></span>
                <span className="greyTxt">{totalItemsCount} Total</span>
                <div className="searchMain">
                  <SearchBox
                    placeholder="Search name/email"
                    onChange={this.onSearch}
                    value={searchText}
                    onClear={this.onClear}
                  />
                </div>
              </div>
              <div className="rightBtn">
                <a
                  className="cyanBtn"
                  onClick={() =>
                    this.setState({ userModalVisible: !userModalVisible })
                  }
                >
                  <i className="icon-addUser" />
                  ADD USER
                </a>
              </div>
            </div>
            <div className="userlisting">
              <div className="listingTopSec">
                <div className="txtSec">
                  <span className="blacTxt">User Management</span>
                  <span className="greyTxt">User management made easy</span>
                </div>
                {this.state.exportList.length > 0 && (
                  <div className="btnSec">
                    <CSVLink
                      data={this.state.exportList}
                      filename={"User List.csv"}
                      className="yellowExportBtn"
                      // target="_blank"
                    >
                      <i className="icon-DownloadNew clubIcon" />
                      Export
                    </CSVLink>
                  </div>
                )}
              </div>
              <div className="userHeader">
                <ul>
                  <li className="yellowIcons"># </li>
                  <li className="nameHead">
                    <a
                      onClick={() => {
                        if (totalItemsCount > 0) {
                          if (
                            sortBy &&
                            sortBy.sFullName &&
                            sortBy.sFullName === -1
                          ) {
                            this.setState(
                              {
                                sortBy: { sFullName: 1 },
                              },
                              () => {
                                this.getList();
                              }
                            );
                          } else {
                            this.setState(
                              {
                                sortBy: { sFullName: -1 },
                              },
                              () => {
                                this.getList();
                              }
                            );
                          }
                        }
                      }}
                    >
                      NAME
                      {totalItemsCount > 0 && (
                        <i
                          className={
                            sortBy["sFullName"] === 1
                              ? "icon-long-arrow upArrow"
                              : "icon-long-arrow downArrow"
                          }
                        />
                      )}
                    </a>
                  </li>
                  <li className="phoneHead">PHONE NUMBER</li>
                  <li className="clubsHead">CLUB</li>
                  <li className="actionsHead">ACTIONS</li>
                </ul>
              </div>
              {totalItemsCount > 0 && (
                <span className="sortDiv">
                  Sort by:{" "}
                  <a
                    className="linkSmall"
                    onClick={() => {
                      if (
                        sortBy &&
                        sortBy.sFullName &&
                        sortBy.sFullName === -1
                      ) {
                        this.setState(
                          {
                            sortBy: { sFullName: 1 },
                          },
                          () => {
                            this.getList();
                          }
                        );
                      } else {
                        this.setState(
                          {
                            sortBy: { sFullName: -1 },
                          },
                          () => {
                            this.getList();
                          }
                        );
                      }
                    }}
                  >
                    Name{" "}
                    <span className="greySmall">
                      {sortBy["sFullName"] === 1 ? "(A to Z)" : "(Z to A)"}
                    </span>
                    <i
                      className={
                        sortBy["sFullName"] === 1
                          ? "icon-long-arrow upArrow"
                          : "icon-long-arrow downArrow"
                      }
                    />
                  </a>
                </span>
              )}

              <Scrollbars className="usersScroll">
                {users.length > 0 &&
                  users.map((item, index) => {
                    let clubNames = "";
                    let selectedClubs = [];
                    return (
                      <ul key={item._id}>
                        <div className="userRow">
                          <li className="noRow">{item.id}</li>
                          <li className="userImgRow">
                            <div className="imgMain">
                              <img
                                src={
                                  item.sProfilePic && item.sProfilePic !== ""
                                    ? item.sProfilePic.replace("upload/","upload/w_50,c_scale/")
                                    : clubImg
                                }
                                className="useIMG"
                                alt="Profile"
                              />
                            </div>
                            <div className="infoSec">
                              <span className="blueTxt">{item.sFullName}</span>
                              <p className="mailTxt">{item.sEmailId}</p>
                            </div>
                          </li>
                          <li className="mobRow">
                            <span className="blueTxt">{`+${item.sCountryCode} ${item.nPhoneNumber}`}</span>
                          </li>
                          <li className="clubsRow">
                            {/* <Tooltip
                              content={item.Club.sClubName}
                              placement="right"
                              className="tooltipBox"
                              zIndex={9999999999}
                            > */}
                              <span className="blueTxt">{item.Club.sClubName}</span>
                            {/* </Tooltip> */}
                          </li>
                          <li className="actionsRow">
                            <a
                              className="clickBtn"
                              onClick={() =>
                                this.setState({
                                  userModalVisible: !userModalVisible,
                                  oUserId: item._id,
                                  defaultForm: {
                                    sFullName: item.sFullName,
                                    sEmailId: item.sEmailId,
                                    nPhoneNumber: item.nPhoneNumber,
                                    sCountryCode: item.sCountryCode,
                                    sProfilePic: item.sProfilePic && item.sProfilePic !=="" ? item.sProfilePic.replace("upload/","upload/w_120,c_scale/") : "",
                                    oClubId: {value:item.Club._id, label:item.Club.sClubName, sClubIdentifier:item.Club.sClubIdentifier},
                                  },
                                })
                              }
                            >
                              <Tooltip
                                content="EDIT"
                                placement="right"
                                className="tooltipSmall"
                              >
                                <i className="icon-edit_pencil" />
                              </Tooltip>
                            </a>

                            <a
                              className="clickBtn"
                              onClick={() =>
                                this.setState({
                                  deleteModalVisible: !deleteModalVisible,
                                  oUserId: item._id,
                                  isStatusModal: false,
                                })
                              }
                            >
                              <Tooltip
                                content="DELETE"
                                placement="right"
                                className="tooltipSmall"
                              >
                                <i className="icon-DeleteNew" />
                              </Tooltip>
                            </a>
                          </li>
                        </div>
                      </ul>
                    );
                  })}
                {users.length === 0 && <Nodata centerTxt="No Data" />}
              </Scrollbars>
              <div className="paginationSec">
                {users.length !== 0 && (
                  <CustomPagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={pageRangeDisplayed}
                    handlePageChange={this.onPageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          showModal={userModalVisible}
          titleName={oUserId === "" ? "Add New User" : "Edit User"}
          userExistsError={this.state.userExistsError}
          errorField={this.state.errorField}
          onHide={() =>
            this.setState({
              userModalVisible: !userModalVisible,
              oUserId: "",
              defaultForm: {},
              userExistsError: "",
            })
          }
          modalType="userModal"
          oUserId={oUserId}
          defaultForm={defaultForm}
          sProfilePic={defaultForm?.sProfilePic}
          onSubmit={(details) => {
            console.log("details===>", details);
            this.onSubmitClick(details);
          }}
        />
        <CustomModal
          showModal={deleteModalVisible}
          _id={oUserId}
          onHide={() =>
            this.setState({
              deleteModalVisible: !deleteModalVisible,
              oUserId: "",
            })
          }
          onClick={(id) => {
            this.onDeleteUser(id);
          }}
          titleName="Delete"
          modalType="deleteModal"
          deletItemName="this user"
        />
        <Footer
          divClassname={
            this.context.isDrawerOpen ? "simpleFooter" : "footersidePadding"
          }
        />
      </div>
    );
  }
}

export default UsersListing;
