const Constant = {
    // BASE_URL: "https://api-communityclub.herokuapp.com/api/v1/",
    // BASE_URL: "https://api-communityclub-staging.herokuapp.com/api/v1/",
    // BASE_URL: "http://localhost:4000/api/v1/",
    // BASE_URL: "https://communityclubapi-0c6f0be58dac.herokuapp.com/api/v1/",
    // BASE_URL: "https://newcommunityapi-staging-cd0e23e7360e.herokuapp.com/api/v1/",
    BASE_URL: "https://newcommunityclubapi-live-45177845f3f4.herokuapp.com/api/v1/",
    // BASE_URL: "https://communityclubnew-451b7f52318b.herokuapp.com/",
    // WEB_URL: "https://communityclub-beta.herokuapp.com/",
    // WEB_URL: "https://communityclubnew-451b7f52318b.herokuapp.com/",
    WEB_URL: "https://newcommunityclubweb-staging-c34bbcdb43f5.herokuapp.com/",
    // WEB_URL: "https://newcommunityclubweb-live-5529d4e139a8.herokuapp.com/",
    // WEB_URL: "http://localhost:3000/",
    API: {
        USER_LOGIN: "user/login",
        USER_FORGOT_PASSWORD: "user/forgotPassword",
        USER_RESET_PASSWORD: "user/resetPassword",
        USER_FIRST_TIME_LOGIN: "user/firstLogin",
        USER_CHECK_FIRST_LOGIN_LINK: "user/checkFirstTimeLoginLink",
        VERIFY_ACCOUNT: "user/checkResetPasswordLink",
        CLUB_LIST: "club/getClubs",
        RECENT_CLUB_LIST: "club/recentlyAdded",
        CLUB_DETAIL: "club/getClubDetail",
        CLUB_DETAIL_BY_POST_ID: "post/getClubDetailByPostId",
        CLUB_ADD: "club/addClub",
        CLUB_EDIT: "club/editClub",
        CLUB_STATUS: "club/enableDisableClub",
        CLUB_DELETE: "club/deleteClub",
        FIXTURES_LIST: "fixtures/getFixtures",
        FIXTURES_ADD: "fixtures/addFixtures",
        FIXTURES_EDIT: "fixtures/editFixtures",
        FIXTURES_STATUS: "fixtures/enableDisableFixtures",
        FIXTURES_DELETE: "fixtures/deleteFixtures",
        SPONSORS_LIST: "clubSponsor/getSponsorList",
        SPONSORS_EXPORT_LIST: "clubSponsor/exportList",
        SPONSORS_ADD: "clubSponsor/addClubSponsor",
        SPONSORS_EDIT: "clubSponsor/editSponsor",
        SPONSORS_STATUS: "clubSponsor/changeStatus",
        SPONSORS_DELETE: "clubSponsor/deleteSponsor",
        USER_LIST: "user/listClubNdCoachAdmin",
        USER_EXPORT_LIST: "user/exportList",
        USER_DETAIL: "user/userProfile",
        USER_ADD: "user/addUser",
        USER_EDIT: "user/editUser",
        USER_STATUS: "user/enableDisableUser",
        USER_DELETE: "user/deleteUsers",
        ADD_HOMEPAGE_LINK: 'homePageLink/addHomePageLink',
        EDIT_HOMEPAGE_LINK: 'homePageLink/editHomePageLink',
        GET_HOMEPAGE_LINK: 'homePageLink/getLinks',
        HOMEPAGE_LINK_DELETE: 'homePageLink/deleteHomePageLink',
        HOMEPAGE_LINK_STATUS: "homePageLink/enableDisableLinkPage",
        HOMEPAGE_EXPORT_LIST: "homePageLink/exportList",
        CONTACT_LIST: "contactUs/getContactList",
        CONTACT_ADD: "contactUs/addContacts",
        CONTACT_EDIT: "contactUs/editContact",
        CONTACT_STATUS: "contactUs/enableDisableContact",
        CONTACT_DELETE: "contactUs/deleteContacts",
        CONTACT_EXPORT_LIST: "contactUs/exportList",
        POSET_DETAILS: "post/singlePostDetails",
    },
    REGEX: {
        email: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
        SPECIAL_CHAR: /[!@#\$%\^\&*\)\(+=._-]/g,
        NUMBER: /[0-9]/g,
        nutrition: /^[a-zA-Z0-9]+$/i,
        Url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
        // Url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
        Facebook: /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/i,
        Twitter: /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/,
        OnlySpaces: /\S/i,
        socialUrl: /^[ A-Za-z0-9_@./#&+-]*$/

    },
    FILE_TYPES: {
        AUDIO: 'audio',
        VIDEO: 'video',
        IMAGE: 'image',
        FILE: 'file'
    },

    MODAL_TYPE: {
        DELETE: 'deleteModal'
    },
    
    MODAL_TITLE: {
        DELETE: "Delete",
        ENABLE: 'Enable',
        DISABLE: 'Disable'
    }
}

export default Constant