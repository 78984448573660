import React, { useState, useEffect, useContext } from "react";
import "./Usermodal.scss";
import TextField from "../TextField/TextField";
import Constant from "../../util/constant";
import Message from "../../util/message";
import HookForm from "../HookForm/HookForm";
import CustomButton from "../../components/CustomButton/button";
import WebService from "../../util/webService";
import CustomDropzone from "../../components/CustomDropzone/CustomDropzone";
import { Controller } from "react-hook-form";
import MainContext from "../../MainContext";
import Spinner from "react-bootstrap/Spinner";
const userForm = {
  sEmailId: {
    name: "sEmailId",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.EmailEmpty,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ErrorMessage.EmailInvalid,
      },
    },
  },
  sFullName: {
    name: "sFullName",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.NameEmpty,
      },
      pattern: {
        value: Constant.REGEX.OnlySpaces,
        message: Message.ErrorMessage.NameEmpty,
      },
    },
  },
  nPhoneNumber: {
    name: "nPhoneNumber",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.PhoneNumberEmpty,
      },
      minLength: {
        value: 10,
        message: Message.ErrorMessage.PhoneNumberLength,
      },
      maxLength: {
        value: 11,
        message: Message.ErrorMessage.PhoneNumberLength,
      },
    },
  },
  sCountryCode: {
    name: "sCountryCode",
  },
  sProfilePic: {
    name: "sProfilePic",
  },
  oClubId: {
    name: "oClubId",
    validate: {
      required: {
        value: true,
        message: "Please select clubs.",
      },
    },
  },
};

const Usermodal = (props) => {
  let {
    closeClick,
    defaultForm,
    // sProfilePic,
    // sFullName,
    // sEmailId,
    // sCountryCode,
    // nPhoneNumber,
    onSubmit,
    // selectedOptions,
    userExistsError,
    errorField,
  } = props;
  console.log(defaultForm,'DEFAULT FORM');
  const [clubIds,setClubIds] = useState(defaultForm?.selectedClubs ? defaultForm?.selectedClubs : []);
  const [clubItems, setClubItems] = useState([]);
  const { isButtonLoading } = useContext(MainContext);
  const handleKeyDown = (e) => {
    console.log(e.key);
    const allowedCharacters = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "-",
      "+",
      " ",
      "(",
      ")",
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Alt",
      "Shift",
      "ArrowRight",
      "Meta",
      "Delete",
      "Enter",
      "Tab",
    ];
    if (!allowedCharacters.includes(e.key)) {
      e.preventDefault();
    }
  };
  const getClubs = async () => {
    let param = {};
    await WebService.postWebServices(
      Constant.API.CLUB_LIST,
      param,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data;
          console.log("mainData from userModal.js===>", mainData);
          if (response.data.status === true) {
            const clubs = mainData.map((club) => {
              return { value: club._id, label: club.sClubName, sClubIdentifier: club.sClubIdentifier};
            });
            setClubItems(clubs);
          } else {
            setClubItems([]);
          }
        }
      }
    );
  };
  useEffect(() => {
    getClubs();
  }, []);
  const df = defaultForm;
  // const df =
  //   props.oUserId !== ""
  //     ? {
  //         sFullName: sFullName,
  //         sProfilePic: sProfilePic,
  //         sEmailId: sEmailId,
  //         sCountryCode: sCountryCode,
  //         nPhoneNumber: nPhoneNumber,
  //       }
  //     : {};
  return (
    <div className="UsermodalMain">
      <HookForm
        defaultForm={df}
        onSubmit={(formMethod) => {
          onSubmit(formMethod);
        }}
      >
        {(formMethod) => {
          console.log(formMethod.getValues(), "errors====");
          return (
            <>
              <div className="leftSec">
                <Controller
                  defaultValue=""
                  name={userForm.sProfilePic.name}
                  control={formMethod?.control}
                  rules={userForm.sProfilePic.validate}
                  errors={formMethod?.errors}
                  as={
                    <CustomDropzone
                      {...props}
                      type="profileImgDropzone"
                      uploadTitle="Upload user's photo"
                      circleIcon="icon-no-display"
                    />
                  }
                />
                <div className="dropDownSec">
                  {/* <TextField
                    labelName="SELECT CLUBS"
                    redStar={true}
                    multiDropdown={true}
                    isDropdown={true}
                    formMethod={formMethod}
                    name={userForm.oClubId.name}
                    errors={formMethod?.errors}
                    rules={userForm.oClubId.validate}
                    options={clubItems}
                    selectedOptions={clubIds}
                  /> */}
                  {console.log(clubItems)}
                  <TextField
                  labelName="SELECT CLUB"
                  redStar={true}
                  isDropdown={true}
                  formMethod={formMethod}
                  name={userForm.oClubId.name}
                  errors={formMethod?.errors}
                  rules={userForm.oClubId.validate}
                  options={clubItems}
                  selectedOptions={clubIds}
                />
                </div>
              </div>
              <div className="divider" />
              <div className="rightSec">
                <div className="formSec">
                  <TextField
                    labelName="FULL NAME"
                    redStar={true}
                    formMethod={formMethod}
                    register={formMethod?.register(userForm.sFullName.validate)}
                    name={userForm.sFullName.name}
                    errors={formMethod?.errors}
                    autoFocus={true}
                    type="text"
                    placeholder="Enter Full Name"
                  />
                  <TextField
                    labelName="EMAIL ADDRESS"
                    redStar={true}
                    formMethod={formMethod}
                    register={formMethod?.register(userForm.sEmailId.validate)}
                    name={userForm.sEmailId.name}
                    customError={
                      errorField !== "" && errorField === "email"
                        ? {
                          name: userForm.sEmailId.name,
                          message: userExistsError,
                        }
                        : ""
                    }
                    errors={formMethod?.errors}
                    autoFocus={true}
                    type="text"
                    placeholder="Enter Email Address"
                    rowsNo="10"
                  />

                  <TextField
                    labelName="PHONE NUMBER"
                    rules={userForm.nPhoneNumber.validate}
                    redStar={true}
                    maxLength="13"
                    // minLength = "10"
                    maskInput={true}
                    // onChange={(e) => onMobileChange(formMethod, e, '          ')}
                    maskType="99999999999"
                    onKeyDown={handleKeyDown}
                    formMethod={formMethod}
                    countryCodeValue={df.sCountryCode}
                    register={formMethod?.register(
                      userForm.nPhoneNumber.validate
                    )}
                    customError={
                      errorField !== "" && errorField === "phone"
                        ? {
                          name: userForm.nPhoneNumber.name,
                          message: userExistsError,
                        }
                        : ""
                    }
                    name={userForm.nPhoneNumber.name}
                    countryCodeName={userForm.sCountryCode.name}
                    errors={formMethod?.errors}
                    placeholder="Enter Phone Number"
                  />
                  <div className="btnGroup">
                    <CustomButton
                      type="submit"
                      title={
                        !isButtonLoading
                          ? props.oUserId !== ""
                            ? "Update"
                            : "Add"
                          : ""
                      }
                      disabled={
                        !formMethod?.formState.isValid || isButtonLoading
                      }
                    >
                      {isButtonLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </CustomButton>

                    <CustomButton
                      className="cancelBtn"
                      onClick={closeClick}
                      title="Cancel"
                    />
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </HookForm>
    </div>
  );
};
Usermodal.defaultProps = {
  selectedClubs: [],
  userExistsError: "",
  errorField: "",
};

export default Usermodal;
