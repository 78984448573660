import React, { useState, useContext } from "react";
import "./Sponsersmodal.scss";
import TextField from "../TextField/TextField";
import Constant from "../../util/constant";
import Message from "../../util/message";
import HookForm from "../HookForm/HookForm";

import facebook from "../../assets/images/facebook.svg";
import twitter from "../../assets/images/twitter.svg";
import CustomButton from "../../components/CustomButton/button";
import Scrollbars from "react-custom-scrollbars";
import CustomDropzone from "../CustomDropzone/CustomDropzone";
import { Controller } from "react-hook-form";
import MainContext from "../../MainContext";
import Spinner from "react-bootstrap/Spinner";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import CheckBox from "../CheckBox/CheckBox";

const sponserForm = {
  title: {
    name: "sSponsorTitle",
    validate: {
      // required: {
      //   value: true,
      //   message: Message.ErrorMessage.TitleEmpty,
      // },
      pattern: {
        value: Constant.REGEX.OnlySpaces,
        message: Message.ErrorMessage.TitleEmpty,
      },
    },
  },
  name: {
    name: "sSponsorName",
    validate: {
      // required: {
      //   value: true,
      //   message: Message.ErrorMessage.SponsersNameEmpty,
      // },
      // pattern: {
      //   value: Constant.REGEX.OnlySpaces,
      //   message: Message.ErrorMessage.SponsersNameEmpty,
      // },
    },
  },
  email: {
    name: "sSponsorEmail",
    validate: {
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ErrorMessage.EmailInvalid,
      },
    },
  },
  mobnumber: {
    name: "sSponsorMobile",
    validate: {
      minLength: {
        value: 10,
        message: Message.ErrorMessage.PhoneNumberLength,
      },
      maxLength: {
        value: 11,
        message: Message.ErrorMessage.PhoneNumberLength,
      },
    },
  },
  links: {
    name: "links",
    validate: {
      validate: {
        hasValidUrl: (value) => {
          return value !== ""
            ? value.match(Constant.REGEX.Url) || "Invalid Link"
            : null;
        },
      },
    },
  },
  facebook: {
    name: "facebook",
    validate: {
      pattern: {
        value: Constant.REGEX.OnlySpaces,
        message: Message.ErrorMessage.FacebookEmpty,
      },
      // validate: {
      //   hasValidUrl: (value) => {
      //     return value !== ""
      //       ? !value.match(Constant.REGEX.socialUrl) || "Invalid URL"
      //       : null;
      //   },
      // },
    },
  },
  twitter: {
    name: "twitter",
    validate: {
      pattern: {
        value: Constant.REGEX.OnlySpaces,
        message: Message.ErrorMessage.TwitterEmpty,
      },
      // validate: {
      //   hasValidUrl: (value) => {
      //     return value !== ""
      //       ? !value.match(Constant.REGEX.socialUrl) || "Invalid URL"
      //       : null;
      //   },
      // },
    },
  },
  Logo: {
    LogoSM: "fSponsorLogoSmallMAX",
    LogoSX: "fSponsorLogoSmallX",
    LogoMM: {
      name: "fSponsorLogoMediumMAX",
      validate: {
        required: {
          value: true,
          message: "Please select club sponser logo",
        },
      },
    },
    LogoMX: {
      name: "fSponsorLogoMediumX",
      validate: {
        required: {
          value: true,
          message: "Please select club sponser logo",
        },
      },
    },
    LogoLM: "fSponsorLogoLargeMAX",
    LogoLX: "fSponsorLogoLargeX",
  },
  Banners: {
    BannerMax: "fSponsorBannerMAX",
    BannerX: "fSponsorBannerX",
    validate: {
      required: {
        value: true,
        message: "Please select club sponser banner",
      },
    },
  },
};
var added = false;
const Sponsersmodal = (props) => {
  let { onSubmit } = props;
  var links =
    typeof props.editDetail.aLinks !== "undefined"
      ? props.editDetail.aLinks
      : [];
  if (typeof links !== "undefined" && !added) {
    if (links.indexOf("") === -1) {
      links.unshift("");
    }
  }

  const { isButtonLoading } = useContext(MainContext);
  const [indexes, setIndexes] = useState(links);
  const [logoName, setLogoName] = useState("Small Logo");
  const [notificationData, setNotificationData] = useState(false);
  let { closeClick, sponsorExistsError } = props;
  const df = props.editDetail ? props.editDetail : {};
  console.log(props, 'PROPS');
  const changeLogoName = (val) => {
    setLogoName(val);
  };
  const getLink = (index) => {
    let value = index > 0 ? indexes[index] : "";
    return value;
  };

  return (
    <div className="SponsersMain">
      <Scrollbars className="modalOuterScroll">
        <HookForm defaultForm={df} onSubmit={(values) => onSubmit(values, notificationData) }>
          {(formMethod) => {
            return (
              <div className="modalmainView">
                <div className="leftSec">
                  <div className="leftSideScroll">
                    <div className="partiton">
                      <label className="mainsubtitle">Banner</label>
                      <div className="bannersSec border">
                        <label>
                          Bigger Device (1242 x 150){" "}
                          <span className="redStar">*</span>
                        </label>
                        <Controller
                          name={sponserForm.Banners.BannerMax}
                          control={formMethod?.control}
                          rules={sponserForm.Banners.validate}
                          errors={formMethod?.errors}
                          as={
                            <CustomDropzone
                              {...props}
                              type="sponserBannerDropzone"
                              subType="max"
                              multiple={false}
                              width={1242}
                              height={150}
                              secHeight={52}
                            />
                          }
                        />
                        <label>
                          Smaller Device (1125 x 150){" "}
                          <span className="redStar">*</span>
                        </label>
                        <Controller
                          name={sponserForm.Banners.BannerX}
                          control={formMethod?.control}
                          rules={sponserForm.Banners.validate}
                          errors={formMethod?.errors}
                          as={
                            <CustomDropzone
                              {...props}
                              type="sponserBannerDropzone"
                              subType="x"
                              multiple={false}
                              width={1125}
                              height={150}
                              secHeight={52}
                            />
                          }
                        />
                      </div>
                    </div>

                    <div className="partiton">
                      <label className="mainsubtitle">Sponsers</label>

                      <div className="bothRecSec border">
                        <div className="dualBox">
                          <div className="rectangleBox">
                            <label className="boxTitle">
                              Bigger Device (1140 x 861){" "}
                              <span className="redStar">*</span>
                            </label>
                            <Controller
                              name={sponserForm.Logo.LogoMM.name}
                              control={formMethod?.control}
                              rules={sponserForm.Logo.LogoMM.validate}
                              errors={formMethod?.errors}
                              as={
                                <CustomDropzone
                                  {...props}
                                  type="sponserSquareDropzone"
                                  subType={"medium-max"}
                                  multiple={false}
                                  width={1140}
                                  height={861}
                                  secWidth={200}
                                  greySecMinHeight={150}
                                />
                              }
                            />
                          </div>
                          <div className="rectangleBox">
                            <label className="boxTitle">
                              Smaller Device (1023 x 771){" "}
                              <span className="redStar">*</span>{" "}
                            </label>
                            <Controller
                              name={sponserForm.Logo.LogoMX.name}
                              control={formMethod?.control}
                              rules={sponserForm.Logo.LogoMX.validate}
                              errors={formMethod?.errors}
                              as={
                                <CustomDropzone
                                  {...props}
                                  type="sponserSquareDropzone"
                                  subType={"medium-x"}
                                  multiple={false}
                                  width={1023}
                                  height={771}
                                  secWidth={190}
                                  greySecMinHeight={142}
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="partiton">
                      <label className="mainsubtitle">Logo</label>

                      <div className="bothRecSec border dropdownWithSec">
                        <CustomDropdown
                          placeholder={
                            logoName == "" ? "Select logo type" : logoName
                          }
                          dropDownItems={["Small Logo", "Large Logo"]}
                          selectedValue={logoName}
                          onSelect={(value) => {
                            changeLogoName(value);
                          }}
                        />
                        <div className={logoName === "Large Logo" ? "dualBox" : "dualBox hide"}>
                          <div className="rectangleBox">
                            <label className="boxTitle">
                              Bigger Device (1140 x 1380){" "}
                            </label>
                            <Controller
                              name={sponserForm.Logo.LogoLM}
                              control={formMethod?.control}
                              rules={sponserForm.Logo.validate}
                              errors={formMethod?.errors}
                              as={
                                <CustomDropzone
                                  {...props}
                                  type="sponserSquareDropzone"
                                  subType={"large-max"}
                                  subType2={"large"}
                                  multiple={false}
                                  width={1140}
                                  height={1380}
                                  secWidth={200}
                                  greySecMinHeight={240}
                                />
                              }
                            />
                          </div>
                          <div className="rectangleBox">
                            <label className="boxTitle">
                              Smaller Device (1023 x 1239){" "}
                            </label>
                            <Controller
                              name={sponserForm.Logo.LogoLX}
                              control={formMethod?.control}
                              rules={sponserForm.Logo.validate}
                              errors={formMethod?.errors}
                              as={
                                <CustomDropzone
                                  {...props}
                                  type="sponserSquareDropzone"
                                  subType={"large-x"}
                                  subType2={"large"}
                                  multiple={false}
                                  width={1023}
                                  height={1239}
                                  secWidth={190}
                                  greySecMinHeight={220}
                                />
                              }
                            />
                          </div>
                        </div>

                        <div className={logoName === "Small Logo" ? "dualBox" : "dualBox hide"}>
                          <div className="rectangleBox">
                            <label className="boxTitle">
                              Bigger Device (1140 x 536){" "}
                            </label>
                            <Controller
                              name={sponserForm.Logo.LogoSM}
                              control={formMethod?.control}
                              rules={sponserForm.Logo.validate}
                              errors={formMethod?.errors}
                              as={
                                <CustomDropzone
                                  {...props}
                                  type="sponserSquareDropzone"
                                  subType={"small-max"}
                                  subType2={"small"}
                                  multiple={false}
                                  width={1140}
                                  height={536}
                                  secWidth={200}
                                  greySecMinHeight={100}
                                />
                              }
                            />
                          </div>
                          <div className="rectangleBox">
                            <label className="boxTitle">
                              Smaller Device (1023 x 516){" "}
                            </label>
                            <Controller
                              name={sponserForm.Logo.LogoSX}
                              control={formMethod?.control}
                              rules={sponserForm.Logo.validate}
                              errors={formMethod?.errors}
                              as={
                                <CustomDropzone
                                  {...props}
                                  type="sponserSquareDropzone"
                                  subType={"small-x"}
                                  subType2={"small"}
                                  multiple={false}
                                  width={1023}
                                  height={516}
                                  secWidth={180}
                                  greySecMinHeight={90}
                                />
                              }
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="rightSec">
                  <div className="formSec">
                    <TextField
                      labelName="SPONSOR NAME"
                      redStar={true}
                      formMethod={formMethod}
                      register={formMethod?.register(
                        sponserForm.name.validate
                      )}
                      name={sponserForm.name.name}
                      errors={formMethod?.errors}
                      autoFocus={true}
                      type="text"
                      placeholder="Enter Sponsor name"
                    />
                    <TextField
                      labelName="ADVERTORIAL"
                      redStar={false}
                      formMethod={formMethod}
                      register={formMethod?.register(sponserForm.title.validate)}
                      name={sponserForm.title.name}
                      errors={formMethod?.errors}
                      type="text"
                      placeholder="Enter Advertorial"
                    />
                    <TextField
                      labelName="EMAIL ADDRESS"
                      redStar={false}
                      formMethod={formMethod}
                      customError={{
                        name: sponserForm.email.name,
                        message: sponsorExistsError,
                      }}
                      register={formMethod?.register(
                        sponserForm.email.validate
                      )}
                      name={sponserForm.email.name}
                      errors={formMethod?.errors}
                      type="text"
                      placeholder="Enter Email Address"
                    />
                    <TextField
                      labelName="PHONE NUMBER"
                      redStar={false}
                      // onChange={(e) => onOTPChange(formMethod, e, '          ')}
                      maskInput={true}
                      maskType="99999999999"
                      formMethod={formMethod}
                      register={formMethod?.register(
                        sponserForm.mobnumber.validate
                      )}
                      rules={sponserForm.mobnumber.validate}
                      name={sponserForm.mobnumber.name}
                      errors={formMethod?.errors}
                      placeholder="Enter Phone Number"
                      countryCodeValue = {df.sSponsorCountryCode}
                    />
                    <div className="labelSec">
                      <p className="labelTxt">WEBSITE LINKS</p>
                      {/* <span className="redStar">*</span> */}
                    </div>
                    <div className="linkSec">
                      <div className="linkField">
                        {indexes.map((value, index) => {
                          // index = index + 1;
                          const fieldName = `${sponserForm.links["name"]}[${index}]`;
                          return (
                            <>
                              <fieldset name={fieldName} key={fieldName}>
                                <div className="cancelView">
                                  <TextField
                                    formMethod={formMethod}
                                    register={formMethod?.register(
                                      sponserForm.links.validate
                                    )}
                                    name={fieldName}
                                    parentName={sponserForm.links["name"]}
                                    isFieldArray={true}
                                    index={index}
                                    defaultValue={getLink(index)}
                                    errors={formMethod?.errors}
                                    type="text"
                                    placeholder="Enter Website Link"
                                  />
                                  <div
                                    className="singleIcon"
                                    id={`Link-${index}`}
                                  >
                                    {index === 0 ? (
                                      <a
                                        className={
                                          formMethod?.watch("links") &&
                                            formMethod?.watch("links")[0] !==
                                            "" &&
                                            !formMethod.errors.links
                                            ? "plusIcon"
                                            : "plusIcon disabled"
                                        }
                                        // onClick={() => setSize(size + 1)}
                                        onClick={() => {
                                          if (
                                            formMethod?.watch("links")[0] ===
                                            "" ||
                                            formMethod.errors.links
                                          ) {
                                            return false;
                                          }
                                          let linksArr = formMethod?.watch(
                                            "links"
                                          );
                                          linksArr.unshift("");
                                          setIndexes(linksArr);
                                          formMethod.setValue(
                                            "links",
                                            linksArr
                                          );
                                          // addLink(index, formMethod);
                                        }}
                                      >
                                        <i className="icon-pluse" />
                                      </a>
                                    ) : (
                                      <a
                                        className="closeIcon"
                                        onClick={() => {
                                          let linksArr = formMethod?.watch(
                                            "links"
                                          );
                                          linksArr.splice(index, 1);
                                          setIndexes(linksArr);
                                          formMethod.setValue(
                                            "links",
                                            linksArr
                                          );
                                          // removeLink(index);
                                        }}
                                      >
                                        <i className="icon-close" />
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </fieldset>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="socialSec">
                      <label>Social Media</label>
                      <TextField
                        labelName="FACEBOOK"
                        formMethod={formMethod}
                        register={formMethod?.register(
                          sponserForm.facebook.validate
                        )}
                        name={sponserForm.facebook.name}
                        errors={formMethod?.errors}
                        type="text"
                        placeholder="Enter Facebook URL"
                        srcImg={facebook}
                      />
                      <TextField
                        labelName="TWITTER"
                        formMethod={formMethod}
                        register={formMethod?.register(
                          sponserForm.twitter.validate
                        )}
                        name={sponserForm.twitter.name}
                        errors={formMethod?.errors}
                        autoFocus={true}
                        type="text"
                        placeholder="Enter Twitter URL"
                        srcImg={twitter}
                      />
                    </div>
                    <div>
                      <CheckBox labelTitle='Send notification to Mobile App' onCheckedChange={(item) => setNotificationData(item)}/>
                    </div>
                    <div className="btnGroup">
                      <CustomButton
                        title={
                          !isButtonLoading
                            ? Object.keys(props.editDetail).length === 0
                              ? "Add"
                              : "Update"
                            : ""
                        }
                        type="submit"
                        disabled={
                          (!formMethod?.formState.isValid || isButtonLoading)  
                          // || formMethod.watch(sponserForm.mobnumber.name) === ''
                        }
                      >
                        {isButtonLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </CustomButton>
                      <CustomButton
                        className="cancelBtn"
                        onClick={closeClick}
                        title="Cancel"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          }}

        </HookForm>
      </Scrollbars>
    </div>
  );
};
Sponsersmodal.defaultProps = {
  editDetail: "",
  errorField: "",
};
export default Sponsersmodal;