import React from "react";
import { Form } from "react-bootstrap";
import "./TextField.scss";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import InputMask from "react-input-mask";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getValueFromObject } from "../../util/helper";

// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
const TextField = (props) => {
  let {
    register,
    errors,
    placeholder,
    multiErrorFields,
    disabled,
    type,
    textarea,
    name,
    autoFocus,
    handleFocus,
    handleBlur,
    iconClass,
    onIconClick,
    onChange,
    onKeyDown,
    formMethod,
    labelName,
    redStar,
    maskInput,
    maskType,
    srcImg,
    rules,
    isDropdown,
    multiDropdown,
    options,
    selectedOptions,
    defaultValue,
    customError,
    maxLength,
    minLength,
    index,
    isFieldArray,
    parentName,
    countryCodeName,
    countryCodeValue,
  } = props;
  let values = formMethod?.getValues();
  let hasError = errors[name] !== undefined;

  const getErrorMessage = () => {
    if (hasError) {
      return <div className="errorMsg show">{errors[name]?.message}</div>;
    } else {
      if (isFieldArray && errors[parentName] && index >= 0) {
        return (
          <div className="errorMsg show">
            {errors[parentName][index]?.message}
          </div>
        );
      }
    }
  };
  return (
    <div className="textField">
      {labelName && <p className="labelTxt">{labelName}</p>}
      {redStar && <span className="redStar">*</span>}
      <Form.Group controlId={name}>
        <div className="inputBox">
          {maskInput ? (
            <div className="countryPhoneGroup">
              <Controller
                as={
                  <PhoneInput
                    // country="gb"
                    // prefix={"+"}
                    countryCodeEditable={false}
                    disableCountryCode={true}
                  />
                }
                value={countryCodeValue}
                // value={getValueFromObject(values, countryCodeName)}
                defaultValue={countryCodeValue}
                // defaultValue={countryCodeValue.replace(/^\D+/g, "")}
                control={formMethod.control}
                name={"sCountryCode"}
              />
              <Controller
                as={<InputMask keepCharPositions={true} />}
                mask={maskType}
                maskChar=""
                control={formMethod.control}
                name={name}
                rules={rules}
                defaultValue={defaultValue}
                placeholder={placeholder}
              />
            </div>
          ) : isDropdown ? (
            <Controller
              defaultValue={selectedOptions!=null && selectedOptions.length > 0 ? selectedOptions : ""}
              // defaultValue={{value: "5f6461073d40730004a93b05",label: "Grenta FC 2008"}}
              control={formMethod?.control}
              name={name}
              placeholder={placeholder}
              rules={rules}
              as={
                <Select
                  onChange={(e) => {
                    onChange && onChange(e);
                  }}
                  options={options}
                  classNamePrefix="dropDown"
                  isMulti={multiDropdown}
                />
              }
            />
          ) : (
            <Form.Control
              defaultValue={defaultValue ? defaultValue : ""}
              maxLength={maxLength}
              minLength={minLength}
              ref={register}
              name={name}
              autoFocus={autoFocus}
              disabled={disabled}
              autoComplete="off"
              type={type}
              as={textarea}
              onBlur={() => handleBlur}
              onKeyDown={onKeyDown}
              onFocus={(e) => {
                handleFocus && handleFocus(e);
              }}
              onChange={(e) => {
                onChange && onChange(e);
              }}
              placeholder={placeholder}
            />
          )}
          {srcImg && <img src={srcImg} className="txtfieldImg" alt="" />}
          {iconClass && (
            <i
              className={"icon-css " + iconClass}
              onClick={() => {
                let formValues = formMethod?.getValues();
                formValues[name] && formValues[name] !== "" && onIconClick();
              }}
            ></i>
          )}
          {multiErrorFields.length > 0 ? (
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ messages }) => {
                if (messages) {
                  let isMultipleError = Object.keys(messages).every(
                    (errKey) =>
                      multiErrorFields.filter((m) => m[errKey] !== undefined)
                        .length > 0
                  );
                  if (isMultipleError) {
                    let arr = [];
                    for (const fieldError of multiErrorFields) {
                      let key = Object.keys(fieldError)[0];
                      let success = Object.keys(messages).includes(key);
                      arr.push(
                        <div key={key} className={success ? "red" : "green"}>
                          <span className="errorMsg show">
                            {fieldError[key]}
                          </span>
                        </div>
                      );
                    }
                    return <div className="fieldError">{arr}</div>;
                  } else {
                    return (
                      <div className="errorMsg show">
                        {isFieldArray && index
                          ? errors[name][index]?.message
                          : errors[name]?.message}
                      </div>
                    );
                  }
                } else {
                  return <div className="errorMsg"></div>;
                }
              }}
            />
          ) : (
            getErrorMessage()
          )}
          {customError && Object.keys(customError).length > 0 ? (
            <div className="errorMsg show">{customError.message}</div>
          ) : (
            <div className="errorMsg">error</div>
          )}
        </div>
      </Form.Group>
    </div>
  );
};

TextField.defaultProps = {
  autoFocus: false,
  value: "",
  countryCodeValue: "1",
  errors: {},
  customError: {},
  multiErrorFields: [],
  noOfWeeks: false,
  isDropdown: false,
  multiDropdown: false,
  maskInput: false,
  selectedOptions: "",
  defaultValue: "",
  isFieldArray: false,
  parentName: "",
};

export default TextField;
